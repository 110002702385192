import supergra from "../../assets/supergra.png";
import logo777 from "../../assets/logo777.png";
import favbet from "../../assets/favbet.png";
import ggbet from "../../assets/ggbet.png";
import slotscity from "../../assets/slotscity.png";
import first from "../../assets/first.png";
import trophy from "../../assets/throphy.png";
import star from "../../assets/star.png";
import hot from "../../assets/hot.png";
import triangle from "../../assets/triangle.png";
import questionmark from "../../assets/questionmark.png";
import chest from "../../assets/chest.png";
import { useEffect, useState } from "react";

export const Main = () => {
  const params = new URLSearchParams(document.location.search);
  const sub1 = params.get("sub1") ?? localStorage.getItem("sub1");
  const sub2 = params.get("sub2") ?? localStorage.getItem("sub2");
  const sub3 = params.get("sub3") ?? localStorage.getItem("sub3");
  const sub4 = params.get("sub4") ?? localStorage.getItem("sub4");
  const sub5 = params.get("sub5") ?? localStorage.getItem("sub5");
  const sub6 = params.get("sub6") ?? localStorage.getItem("sub6");
  const sub7 = params.get("sub7") ?? localStorage.getItem("sub7");
  const sub8 = params.get("sub8") ?? localStorage.getItem("sub8");
  const sub9 = params.get("sub9") ?? localStorage.getItem("sub9");
  const [showOffers, setShowOffers] = useState([]);

  useEffect(() => {
    const showOffersParam = params.get("showOffers");

    if (showOffersParam) {
      const offerIds = showOffersParam.split(",").map(Number);
      setShowOffers(offerIds);
    } else {
      setShowOffers([]);
    }
  }, []);

  if (sub1 !== null && sub1) {
    localStorage.setItem("sub1", sub1);
  }

  if (sub2 !== null && sub2) {
    localStorage.setItem("sub2", sub2);
  }

  if (sub3 !== null && sub3) {
    localStorage.setItem("sub3", sub3);
  }

  if (sub4 !== null && sub4) {
    localStorage.setItem("sub4", sub4);
  }

  if (sub5 !== null && sub5) {
    localStorage.setItem("sub5", sub5);
  }

  if (sub6 !== null && sub6) {
    localStorage.setItem("sub6", sub6);
  }

  if (sub7 !== null && sub7) {
    localStorage.setItem("sub7", sub7);
  }
  if (sub8 !== null && sub8) {
    localStorage.setItem("sub8", sub8);
  }
  if (sub9 !== null && sub9) {
    localStorage.setItem("sub9", sub9);
  }

  const clickHandler = (t, tgLink, offer) => {
    let e = "deep-link";
    let r = true;
    let n = {
      variables: {},
    };
    if (sub1 !== null) {
      n.variables["sub1"] = sub1;
    }
    if (sub2 !== null) {
      n.variables["sub2"] = sub2;
    }
    if (sub3 !== null) {
      n.variables["sub3"] = sub3;
    }
    if (sub4 !== null) {
      n.variables["sub4"] = sub4;
    }
    if (sub5 !== null) {
      n.variables["sub5"] = sub5;
    }
    if (sub6 !== null) {
      n.variables["sub6"] = sub6;
    }
    if (sub7 !== null) {
      n.variables["sub7"] = sub7;
    }
    if (sub8 !== null) {
      n.variables["sub8"] = sub8;
    }
    if (sub9 !== null) {
      n.variables["sub9"] = sub9;
    }
    let a = {
      context: { range: [], scope: {}, variables: n },
      messengers: [
        {
          key: "?",
          name: "telegram",
          parameter: "start",
          prefixes: ["tg://", "https://t.me/"],
        },
      ],
      delimiter: "|",
      url: "/api/i/store",
    };
    // document
    //   .getElementById("tg://resolve?start=ZGw6MTk5Mzc1&domain=sloto_ua_bot")
    //   .addEventListener("click", function (t) {
    // eslint-disable-next-line no-unused-expressions
    t.preventDefault(),
      (function (e) {
        let t = new XMLHttpRequest();
        // eslint-disable-next-line no-unused-expressions
        t.open("POST", "https://eyq13.customer.smartsender.eu/api/i/store"),
          t.setRequestHeader("Content-type", "application/json"),
          t.setRequestHeader("X-Requested-With", "XMLHttpRequest"),
          (t.onreadystatechange = function () {
            if (4 === this.readyState && 200 === this.status) {
              let t = JSON.parse(this.responseText);
              window.location.href = (function (e, t) {
                let r = a.messengers.find(function (t) {
                    return t.prefixes.find(function (t) {
                      return 0 === e.indexOf(t);
                    });
                  }),
                  n = e.split(r.key),
                  s = n[1].split("&");
                return (
                  s.forEach(function (e, n) {
                    let i = e.split("=");
                    if (r.parameter === i[0]) {
                      let e = btoa(atob(i[1]) + a.delimiter + t.id).replace(
                        /=/g,
                        ""
                      );
                      s.splice(n, 1, [r.parameter, e].join("="));
                    }
                  }),
                  n[0] + r.key + s.join("&")
                );
              })(e, t);
            }
          }),
          t.send(
            (function (e) {
              return (
                Object.keys(a.context).forEach(function (t) {
                  Object.keys(e).includes(t) || (e[t] = {});
                }),
                JSON.stringify(
                  r
                    ? ((t = e),
                      new URLSearchParams(window.location.search).forEach(
                        function (e, r) {
                          t.variables.hasOwnProperty(r) || (t.variables[r] = e);
                        }
                      ),
                      t)
                    : e
                )
              );
              var t;
            })(n)
          );
      })(tgLink);
    setTimeout(() => {
      if (offer === "super") {
        onWebsiteSuperBtnClick();
      } else if (offer === "favbet") {
        onWebsiteFavBtnClick();
      } else if (offer === "777") {
        onWebsite777BtnClick();
      } else if (offer === "ggbet") {
        onWebsiteGGbetBtnClick();
      } else if (offer === "slotscity") {
        onWebsiteSlotscityBtnClick();
      } else if (offer === "first") {
        onWebsiteFirstBtnClick();
      }
    }, 3000);
  };

  function onWebsiteFavBtnClick() {
    window.location.href =
      `https://hypertraff.top/tsnvWhrS?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteSuperBtnClick() {
    window.location.href =
      `https://hypertraff.top/D5FBxB9T?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsite777BtnClick() {
    window.location.href =
      `https://hypertraff.top/4WjfCSnP?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteGGbetBtnClick() {
    window.location.href =
      `https://hypertraff.top/2XrMyF3M?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteSlotscityBtnClick() {
    window.location.href =
      `https://househub.top/f5gWHqcx?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  function onWebsiteFirstBtnClick() {
    window.location.href =
      `https://househub.top/C4yQxJNC?a=b` +
      (sub1 !== null ? `&sub1=${sub1}` : "") +
      (sub2 !== null ? `&sub2=${sub2}` : "") +
      (sub3 !== null ? `&sub3=${sub3}` : "") +
      (sub4 !== null ? `&sub4=${sub4}` : "") +
      (sub5 !== null ? `&sub5=${sub5}` : "") +
      (sub6 !== null ? `&sub6=${sub6}` : "") +
      (sub7 !== null ? `&sub7=${sub7}` : "") +
      (sub8 !== null ? `&sub8=${sub8}` : "") +
      (sub9 !== null ? `&sub9=${sub9}` : "");
  }

  const offers = [
    {
      id: 2,
      component: (
        <div className="main__item">
          <img src={hot} alt="hot" className="main__hot" />
          <img src={slotscity} alt="logotype" width={254} height={76} />
          <p className="main__text--top main__text--topSmaller">
            100 ФРІСПІНІВ
          </p>
          <p className="main__text--bottom">+50% ДО ДЕПОЗИТУ</p>
          <div className="main__fade">
            <img src={star} alt="Зірка" className="main__star" />
            <p className="main__fade--text">Промокод: BONUS24</p>
            <img src={star} alt="Зірка" className="main__star" />
          </div>
          <button
            type="button"
            className="main--button--top"
            onClick={(e) => {
              clickHandler(
                e,
                "tg://resolve?start=ZGw6MjI4MjM4&domain=hotspin_bot",
                "slotscity"
              );
            }}
          >
            <div className="main__wrap--top">
              <p className="main__text--btm">отримати код</p>
              <svg
                className="main__svg--arrow"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="16"
                viewBox="0 0 24 16"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.3431 0.928941L23.7071 7.2929C24.0976 7.68343 24.0976 8.31659 23.7071 8.70712L17.3431 15.0711C16.9526 15.4616 16.3195 15.4616 15.9289 15.0711C15.5384 14.6806 15.5384 14.0474 15.9289 13.6569L20.5858 9.00001H1C0.447715 9.00001 0 8.55229 0 8.00001C0 7.44772 0.447715 7.00001 1 7.00001H20.5858L15.9289 2.34315C15.5384 1.95263 15.5384 1.31947 15.9289 0.928941C16.3195 0.538416 16.9526 0.538416 17.3431 0.928941Z"
                />
              </svg>
            </div>
            <img
              src={triangle}
              alt="трикутник"
              className="main__svg--triangle"
            />
            <p className="main__btn--text">q10mt</p>
            <svg
              className="main__paralelogram"
              width="137"
              height="91"
              viewBox="0 0 137 91"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M137 0L137 91H0.5L32.2642 0H137Z"
                fill="url(#paint0_linear_1963_16832)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1963_16832"
                  x1="0.499999"
                  y1="9.89131"
                  x2="147.863"
                  y2="57.0723"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FF0D09" />
                  <stop offset="1" stop-color="#FF5C00" />
                </linearGradient>
              </defs>
            </svg>
          </button>
          {/* <button
              type="button"
              className="main__button"
              onClick={(e) => {
                clickHandler(
                  e,
                  "tg://resolve?start=ZGw6MjEwNTMy&domain=spin_ua_bonus_bot",
                  "slotscity"
                );
              }}
            >
              Забрати бонус
            </button> */}
        </div>
      ),
    },
    {
      id: 1,
      component: (
        <div className="main__item">
          <img src={hot} alt="hot" className="main__hot" />
          <img src={first} alt="logotype" width={254} height={76} />
          <p className="main__text--top">150 000₴</p>
          <p className="main__text--bottom">+125 ФРІСПІНІВ</p>
          <div className="main__fade">
            <img src={star} alt="Зірка" className="main__star" />
            <p className="main__fade--text">Новий бонус</p>
            <img src={star} alt="Зірка" className="main__star" />
          </div>
          <button
            type="button"
            className="main--button--top"
            onClick={(e) => {
              clickHandler(
                e,
                "tg://resolve?start=ZGw6MjI4MjM1&domain=hotspin_bot",
                "first"
              );
            }}
          >
            <div className="main__wrap--top">
              <p className="main__text--btm">отримати код</p>
              <svg
                className="main__svg--arrow"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="16"
                viewBox="0 0 24 16"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.3431 0.928941L23.7071 7.2929C24.0976 7.68343 24.0976 8.31659 23.7071 8.70712L17.3431 15.0711C16.9526 15.4616 16.3195 15.4616 15.9289 15.0711C15.5384 14.6806 15.5384 14.0474 15.9289 13.6569L20.5858 9.00001H1C0.447715 9.00001 0 8.55229 0 8.00001C0 7.44772 0.447715 7.00001 1 7.00001H20.5858L15.9289 2.34315C15.5384 1.95263 15.5384 1.31947 15.9289 0.928941C16.3195 0.538416 16.9526 0.538416 17.3431 0.928941Z"
                />
              </svg>
            </div>
            <img
              src={triangle}
              alt="трикутник"
              className="main__svg--triangle"
            />
            <p className="main__btn--text">q10mt</p>
            <svg
              className="main__paralelogram"
              width="137"
              height="91"
              viewBox="0 0 137 91"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M137 0L137 91H0.5L32.2642 0H137Z"
                fill="url(#paint0_linear_1963_16832)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1963_16832"
                  x1="0.499999"
                  y1="9.89131"
                  x2="147.863"
                  y2="57.0723"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FF0D09" />
                  <stop offset="1" stop-color="#FF5C00" />
                </linearGradient>
              </defs>
            </svg>
          </button>
        </div>
      ),
    },
  ];

  return (
    <main className="main">
      <div className="container">
        <div className="main__top--wrap">
          <img
            src={trophy}
            alt="Топ казино"
            width="36px"
            height="36px"
            className="main__top--trophy"
          />
          <p className="main__title">топ казино</p>
          <img
            src={trophy}
            alt="Топ казино"
            width="36px"
            height="36px"
            className="main__top--trophy"
          />
        </div>
        <div className="main__thumb">
          {showOffers.length === 0 && offers.map((offer) => offer.component)}
          {showOffers.length > 0 &&
            showOffers.map((id) => {
              const offer = offers.find((offer) => offer.id === id);
              return offer ? offer.component : null;
            })}
          <div className="main__item2" style={{ gap: 0 }}>
            <div className="main__item2--wrap">
              <img
                src={questionmark}
                alt="question mark"
                className="main__item2--img"
              />
              <p className="main__item2--title">Секретні бонуси</p>
            </div>
            <div className="main__item2--thumb">
              <img src={chest} alt="chest" className="main__item2--img2" />
              <p className="main__item2__text">
                Підписуйся на наш телеграм та першим дізнавайся про секретні
                бонуси
              </p>
            </div>
            <button
              type="button"
              className="main__item2--btn"
              onClick={(e) => {
                clickHandler(
                  e,
                  "tg://resolve?start=ZGw6MjI4Mjc0&domain=secret_bonuses_bot"
                );
              }}
            >
              Отримати бонуси
            </button>
          </div>
          {/* <div
            className="main__item"
            style={{
              background: "linear-gradient(180deg, #892900 0%, #0C0802 100%)",
            }}
          >
            <img src={ggbet} alt="logotype" width={254} height={76} />
            <p className="main__text--top">12000₴</p>
            <p className="main__text--bottom" style={{ color: "#FAFF00" }}>
              +50 ФРІСПІНІВ
            </p>
            <div className="main__fade">
              <p className="main__fade--text">Новий бонус</p>
            </div>
            <button
              type="button"
              className="main__button"
              onClick={(e) => {
                clickHandler(
                  e,
                  "tg://resolve?start=ZGw6MjA5MDY4&domain=spin_ua_bonus_bot",
                  "ggbet"
                );
              }}
            >
              Забрати бонус
            </button>
          </div> */}
          {/* <div className="main__item">
            <img src={supergra} alt="logotype" width={254} height={76} />
            <p className="main__text--top">100</p>
            <p className="main__text--bottom">ФРІСПІНІВ</p>
            <div className="main__fade">
              <p className="main__fade--text">БЕЗ ВІДІГРАШУ</p>
            </div>
            <button
              type="button"
              className="main__button"
              onClick={(e) => {
                clickHandler(
                  e,
                  "tg://resolve?start=ZGw6MjA2NzI1&domain=spin_ua_bonus_bot",
                  "super"
                );
              }}
            >
              Забрати бонус
            </button>
          </div> */}
          {/* <div
            className="main__item"
            style={{
              background: "linear-gradient(180deg, #560B57 0%, #1D081C 100%)",
            }}
          >
            <img src={favbet} alt="logotype" width={254} height={76} />
            <p className="main__text--top">100</p>
            <p className="main__text--bottom">ФРІСПІНІВ</p>
            <div className="main__fade">
              <p className="main__fade--text">БЕЗ ВІДІГРАШУ</p>
            </div>
            <button
              type="button"
              className="main__button"
              onClick={(e) => {
                clickHandler(
                  e,
                  "tg://resolve?start=ZGw6MjA2NzIy&domain=spin_ua_bonus_bot",
                  "favbet"
                );
              }}
            >
              Забрати бонус
            </button>
          </div> */}
          {/* <div
            className="main__item"
            style={{
              background: "linear-gradient(180deg, #091D67 0%, #0F081D 100%)",
            }}
          >
            <img src={favbet} alt="logotype" width={254} height={76} />
            <p className="main__text--top">25 ФРІСПІНІВ</p>
            <p className="main__text--bottom" style={{ color: "#FAFF00" }}>
              БЕЗ ВІДІГРАШУ
            </p>
            <div
              className="main__fade"
              style={{
                background:
                  "radial-gradient(50% 15689.8% at 50% 50%, #FF2F93 0%, rgba(255, 47, 147, 0) 100%)",
              }}
            >
              <p className="main__fade--text">Промокод - fav25fs</p>
            </div>
            <button
              type="button"
              className="main__button"
              onClick={(e) => {
                clickHandler(
                  e,
                  "tg://resolve?start=ZGw6MjA4NDA1&domain=spin_ua_bonus_bot",
                  "favbet"
                );
              }}
            >
              Забрати бонус
            </button>
          </div> */}
          {/* <div
            className="main__item"
            style={{
              background: "linear-gradient(180deg, #570B0B 0%, #1D0808 100%)",
            }}
          >
            <img src={logo777} alt="logotype" width={254} height={76} />
            <p className="main__text--top">200 ГРН</p>
            <p className="main__text--bottom" style={{ height: "30px" }}></p>
            <div className="main__fade">
              <p className="main__fade--text">БЕЗ ДЕПОЗИТУ</p>
            </div>
            <button
              type="button"
              className="main__button"
              onClick={(e) => {
                clickHandler(
                  e,
                  "tg://resolve?start=ZGw6MjA2NzI4&domain=spin_ua_bonus_bot",
                  "777"
                );
              }}
            >
              Забрати бонус
            </button>
          </div> */}
        </div>
      </div>
    </main>
  );
};
